<template>
  <div class="navbar" @click.stop>
    <div v-if="!isMobile" class="navbar-item-content">
      <img data-anima="top" v-if="panelLogo" class="navbar-logo" :src="panelLogo"  @click="$router.push(`/home`)"/>
      <span data-anima="top" :class="`default-font-${$store.state.metas.metas.default_font}`" class="navbar-title"  @click="$router.push(`/home`)">{{ panelName }} </span>
      <span
        :key="'/home'"
        @click="$router.push(`/home`)"
        :class="`navbar-item navbar-item-${isActive(
          '/home',
        )}-${theme}`"
      >
        {{ $t("navbar.start") }}
      </span>
       <span
        data-anima="top"
        :key="'/home?filter=meus-cursos'"
        @click="$router.replace(`/home?filter=meus-cursos`)"
        :class="`navbar-item navbar-item-${isActive(
          '/home?filter=meus-cursos',
        )}-${theme}`"
      >
        {{ $t("navbar.my_courses") }}
      </span>
      <template v-if="likeStudent">
        <span
          data-anima="top"
          v-for="item in menuItemsStudent"
          :key="item.path"
          @click="$router.push(`/${item.path}`)"
          :class="`navbar-item navbar-item-${isActive(item.path)}-${theme}`"
        >
          {{ item.name }}
        </span>
      </template>
      <template v-else>
        <span
          data-anima="top"
          v-for="item in menuItemsAdmin.filter((item) =>
            item.permission.includes(role),
          )"
          :key="item.path"
          @click="$router.push(`/${item.path}`)"
          :class="`navbar-item navbar-item-${isActive(item.path)}-${theme}`"
        >
          {{ item.name }}
        </span>
      </template>
    </div>
    <div v-if="getIsMobile && $route.name !== 'Home'" class="title-mobile">
      <div @click="$router.go(-1)" class="btn-back-navbar">
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1 9L9 17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <span>
        {{ $route.name }}
      </span>
      <div style="width: 35px;"></div>
    </div>
    <FillHorizontal />
    <Menu />
  </div>
</template>

<script>
import Menu from '../menu/index.vue';
import FillHorizontal from '../spacer/index.vue';
import MetaService from '@/services/resources/MetaService';
const serviceMeta = MetaService.build();
import { mapGetters } from "vuex";
import Cookies from 'js-cookie';
import { EventBus } from '@/main';

export default {
  props: ['isMobile'],
  components: {
    Menu,
    FillHorizontal,
  },
  computed: {
    ...mapGetters(['getIsMobile', 'currentSite']),
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    panelName() {
      return this.currentSite ? this.currentSite.currentSite.name : '...';
    },
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    },
  },
  created() {
    this.getViewLikeStudent();
    this.checkOwnerOrAdmin();
    this.getLogo();
    EventBus.$on('reloadHome', (panel) => {
      this.getViewLikeStudent();
      this.checkOwnerOrAdmin();
      this.getLogo();
    });
  },
  data() {
    return {
      likeStudent: false,
      showMenuItems: false,
      isOwnerOrAdmin: false,
      panelLogo: null,
      role: "",
      menuItemsAdmin: [        
        // { icon: 'search-mobile.svg', path: "search", name: "Buscar", permission:['admin', 'owner', 'student'] },
        {
          icon: 'certificates-mobile.svg',
          path: 'certificates',
          name: this.$t("navbar.certificates"),
          permission: ['admin', 'owner','student', 'student_moderator'],
        },
        // { icon: 'download-mobile.svg', path: "downloads", name: "Downloads", permission:['admin', 'owner', 'student'] },
        {
          icon: '',
          path: 'assinatura',
          name: this.$t("navbar.signature"),
          permission: ['admin', 'owner'],
        },
        {
          icon: '',
          path: 'comunidade',
          name: this.$t("navbar.community"),
          permission: ['admin', 'owner'],
        },
        {
          icon: '',
          path: 'members',
          name: this.$t("navbar.members"),
          permission: ['admin', 'owner', 'student_moderator'],
        },
        {
          icon: '',
          path: 'configuracoes',
          name: this.$t("navbar.settings"),
          permission: ['admin', 'owner'],
        },
      ],
      menuItemsStudent: [        
        {
          icon: 'certificates-mobile.svg',
          path: 'certificates',
          name: this.$t("navbar.certificates"),
          permission: ['admin', 'owner','student', 'student_moderator'],
        },
      ],
    };
  },
  methods: {
    isActive(path) {
      let isActive = path == this.$router.resolve(this.$route).href;
      return isActive ? "active" : "unactive";
    },
    toggle() {
      this.showMenuItems = !this.showMenuItems;
    },
    checkOwnerOrAdmin() {
      if (
        ['admin', 'owner'].includes(
          this.role,
        )
      ) {
        this.isOwnerOrAdmin = true;
      }
    },
    getLogo() {
      serviceMeta
        .search('keys[]=logo')
        .then((resp) => {
          this.panelLogo = resp.logo;
        });
    },
    getViewLikeStudent() {
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");
      if ((permission === "admin" || permission === "owner" || permission === "student_moderator") && (typeView === 1 || typeView === '1')) {
        this.role = 'student'
        this.likeStudent = true;
      } else {
        this.role = permission;
        this.likeStudent = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 9;
  padding-top: 1.5rem;
  padding-bottom: 0 !important;
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-logo {
  min-height: 22px;
  max-height: 22px;
  margin-right: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.navbar-logo-empty {
  min-height: 22px;
  min-width: 22px;
  max-width: 22px;
  max-height: 22px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: var(--bordercolor) !important;
  cursor: pointer;
}
.navbar-title {
  font-family: Montserrat Alternates;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 30px;
  padding-left: 0px;
  white-space: nowrap;
  color: var(--maincolor);
  cursor: pointer;
}
.navbar-item {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 30px;
  padding-left: 0px;
  white-space: nowrap;
  cursor: pointer;
}
.navbar-item-active-light {
  color: #16171a !important;
}
.navbar-item-active-dark {
  color: #fff !important;
}
.navbar-item-unactive-light {
  color: rgba(70, 70, 71, 0.5) !important;
}
.navbar-item-unactive-dark {
  color: rgba(255, 255, 255, 0.5) !important;
}
.navbar-item-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0px;
}
.title-mobile {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--fontcolor-v2)
}
.btn-back-navbar {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--background2-v2);
  svg {
    stroke: var(--fontcolor-v2);
  }
}
</style>
