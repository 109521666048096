<template>
  <div class="background">
    <transition name="fade">
      <button v-show="righClicked || showInMobileOnScroll" @click="scrollLeft" class="scroll-button left-shaddow" :class="`scroll-button-${categoryId}`" ref="leftButton">
        <div style="top: 0px !important;" class="arrow left-arrow"><i class="fa fa-angle-left"></i></div>
      </button>
    </transition>
    <div
      ref="horizontal"
      class="horizontal-scroll"
      @mousedown="startDrag"
      @touchstart="startDrag"
      @mousemove="drag"
      @touchmove="drag"
      @mouseup="endDrag"
      @touchend="endDrag"
    >
      <div
        class="content-scroll"
        @touchmove="handleScroll"
        ref="content"
        :has-carousel="hasCarousel"
      >
        <slot></slot>
      </div>
    </div>
    <button style="right: 0px !important;"  v-show="showButton" @click="scrollRight" class="scroll-button right-shaddow" :class="`scroll-button-${categoryId}`" ref="rightButton">
      <div style="top: 0px !important;" class="arrow right-arrow"><i class="fa fa-angle-right"></i></div>
    </button>
  </div>
</template>

<script>
// Services
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import { mapActions } from 'vuex';
import { EventBus } from "@/main.js"

export default {
  name: "HorizontalScroll",
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      righClicked: 0,
      checking: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      hasCarousel: false,
      showInMobileOnScroll: false,
      showButton: false,
      contentScrollWidth: 0,
    };
  },
  mounted() {
    this.getCarousel();
    this.observeChanges();
    this.contentScrollWidth = this.$refs.content.scrollWidth;
    EventBus.$on("template-scroll", this.disableShowButtonInMobileScroll);
  },
  watch: {
    contentScrollWidth(newScrollWidth) {
      this.updateButtonVisibility(newScrollWidth);
    }
  },
  methods: {
    ...mapActions(['startDragging', 'stopDragging']),
    disableShowButtonInMobileScroll() {
      const leftButton = this.$refs.leftButton;
      const rightButton = this.$refs.rightButton;
      if (this.isMobile) {
        this.showInMobileOnScroll = false;
        leftButton.style.visibility = 'hidden';
        rightButton.style.visibility = 'hidden';
      }
    },
    checkButtonVisibility() {
      const containerWidth = this.$refs.horizontal.clientWidth;
      const scrollLeft = this.$refs.horizontal.scrollLeft;
      const scrollWidth = this.$refs.horizontal.scrollWidth;

      this.$refs.leftButton.style.display = scrollLeft <= 0 ? 'none' : 'block';
      this.$refs.rightButton.style.display = scrollLeft + containerWidth >= scrollWidth ? 'none' : 'block';
    },
    updateButtonVisibility(scrollWidth) {
      const horizontalElement = this.$refs.horizontal; 
      if (!horizontalElement) return;
      let containerWidth = horizontalElement.clientWidth;
      this.showButton = scrollWidth > containerWidth || this.showInMobileOnScroll;
    },
    observeChanges() {
      const contentElement = this.$refs.content;
      if (contentElement) {
        const observer = new MutationObserver(() => {
          this.contentScrollWidth = contentElement.scrollWidth;
          this.checkButtonVisibility();
          
        });
        observer.observe(contentElement, { attributes: true });
        this.$once('hook:beforeDestroy', () => {
          observer.disconnect();
        });
      }
    },
    scrollLeft() {
      this.righClicked--;
      this.$refs.horizontal.scrollBy({ left: -540, behavior: "smooth" });
      this.checkButtonVisibility();
      if (this.righClicked === 0) {
        this.disableShowButtonInMobileScroll();
      }
    },
    scrollRight() {
      this.righClicked++;
      this.$refs.horizontal.scrollBy({ left: 540, behavior: "smooth" });
      this.checkButtonVisibility();
    },
    handleScroll() {
      if (this.isMobile && this.updateButtonVisibility() ) {
        let elements = document.getElementsByClassName(`scroll-button-${this.categoryId}`);
        elements[0].style.visibility = 'visible';
        elements[1].style.visibility = 'visible';
        this.showInMobileOnScroll = true;
        if (!this.checking) {
          this.scrolled();
        }
      }
    },
    scrolled() {
      this.righClicked++;
    },
    startDrag(event) {
      this.handleScroll();
      this.isDragging = true;
      this.startDragX = event.clientX || event.touches[0].clientX;
      this.startScrollLeft = this.$refs.horizontal.scrollLeft;
    },
    drag(event) {
      if (this.isDragging) {
        this.startDragging();
        const scrollAmount =
          this.startScrollLeft +
          (this.startDragX - (event.clientX || event.touches[0].clientX));
        this.$refs.horizontal.scrollLeft = scrollAmount;
      }
    },
    endDrag() {
      this.isDragging = false;
      setTimeout(() => {
        this.stopDragging();
      }, 1);
    },
    async getCarousel() {
      await serviceMeta.search("keys[]=home_carousel").then((resp) => {
        this.hasCarousel = resp.home_carousel === "on";
      });
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-leave-active {
  opacity: 0;
}
.background {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
}
.horizontal-scroll {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
}

.scroll-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: #333;
  font-size: 1rem;
  z-index: 999;
  height: 100%;
  display: flex;
}
@media screen and (min-width: 768px) {
  .scroll-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    z-index: 999;
    height: 100%;
    display: flex;
  }
}

.content-scroll {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  touch-action: pan-y;
  min-width: fit-content;
  overflow: hidden;
  right: 0;
  z-index: 30;
  gap: 5px;
}

.content-scroll[has-carousel] {
  flex-wrap: nowrap;
}

.left {
  left: 0;
  height: 50px;
  width: 25px;
  border-radius: 50%;
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}

.right {
  right: 30px !important;
  top: 50%;
  position: relative;
}

.right-shaddow {
  border-radius: 10px 0 0 10px;
  background-color: rgba(0,0,0,0.3);
}
.left-shaddow {
  border-radius: 10px 0 0 10px;
  left: -41px;
  background-color: rgba(0,0,0,0.3);
}
.scroll-button .arrow:hover {
  scale: 1.3;
}
</style>
